//- Component Imports
import { Svg } from './Svg';

interface DAOIconProps {
	title?: string;
	stroke?: string;
}

const DAOIcon = ({ title = '', stroke = '' }: DAOIconProps) => (
	<Svg title={title} width="24" height="24" viewBox="0 0 24 24" fill="none">
		<>
			<path
				d="M12 3.44444C12.675 3.44444 13.2222 2.89724 13.2222 2.22222C13.2222 1.54721 12.675 1 12 1C11.325 1 10.7778 1.54721 10.7778 2.22222C10.7778 2.89724 11.325 3.44444 12 3.44444ZM12 3.44444V7.11111M3.44444 12C3.44444 11.325 2.89724 10.7778 2.22222 10.7778C1.54721 10.7778 1 11.325 1 12C1 12.675 1.54721 13.2222 2.22222 13.2222C2.89724 13.2222 3.44444 12.675 3.44444 12ZM3.44444 12H7.11111M12 20.5556C11.325 20.5556 10.7778 21.1028 10.7778 21.7778C10.7778 22.4528 11.325 23 12 23C12.675 23 13.2222 22.4528 13.2222 21.7778C13.2222 21.1028 12.675 20.5556 12 20.5556ZM12 20.5556V16.8889M18.0497 18.0495C17.5724 18.5269 17.5724 19.3007 18.0497 19.778C18.527 20.2553 19.3009 20.2553 19.7782 19.778C20.2555 19.3007 20.2555 18.5269 19.7782 18.0495C19.3009 17.5722 18.527 17.5722 18.0497 18.0495ZM18.0497 18.0495L15.3611 15.3611M16.8889 12C16.8889 14.7001 14.7001 16.8889 12 16.8889M16.8889 12C16.8889 9.29994 14.7001 7.11111 12 7.11111M16.8889 12H20.8611M12 16.8889C9.29994 16.8889 7.11111 14.7001 7.11111 12M7.11111 12C7.11111 9.29994 9.29994 7.11111 12 7.11111M5.88889 18.1111L8.54303 15.457M5.88889 5.88889L8.54303 8.54303M18.1111 5.88889L15.457 8.54303M5.95034 4.22197C6.42764 4.69927 6.42764 5.47314 5.95034 5.95045C5.47303 6.42776 4.69916 6.42776 4.22185 5.95045C3.74455 5.47314 3.74455 4.69927 4.22185 4.22197C4.69916 3.74466 5.47303 3.74466 5.95034 4.22197ZM19.7782 5.95034C19.3009 6.42764 18.527 6.42764 18.0497 5.95034C17.5724 5.47303 17.5724 4.69916 18.0497 4.22185C18.527 3.74455 19.3009 3.74455 19.7782 4.22185C20.2555 4.69916 20.2555 5.47303 19.7782 5.95034ZM5.9503 19.7782C5.47299 20.2555 4.69913 20.2555 4.22182 19.7782C3.74451 19.3009 3.74451 18.527 4.22182 18.0497C4.69913 17.5724 5.47299 17.5724 5.9503 18.0497C6.42761 18.527 6.42761 19.3009 5.9503 19.7782ZM21.7778 10.7778C22.4528 10.7778 23 11.325 23 12C23 12.675 22.4528 13.2222 21.7778 13.2222C21.1028 13.2222 20.5556 12.675 20.5556 12C20.5556 11.325 21.1028 10.7778 21.7778 10.7778Z"
				stroke={stroke}
			/>
			<path
				d="M12 3.44444C12.675 3.44444 13.2222 2.89724 13.2222 2.22222C13.2222 1.54721 12.675 1 12 1C11.325 1 10.7778 1.54721 10.7778 2.22222C10.7778 2.89724 11.325 3.44444 12 3.44444ZM12 3.44444V7.11111M3.44444 12C3.44444 11.325 2.89724 10.7778 2.22222 10.7778C1.54721 10.7778 1 11.325 1 12C1 12.675 1.54721 13.2222 2.22222 13.2222C2.89724 13.2222 3.44444 12.675 3.44444 12ZM3.44444 12H7.11111M12 20.5556C11.325 20.5556 10.7778 21.1028 10.7778 21.7778C10.7778 22.4528 11.325 23 12 23C12.675 23 13.2222 22.4528 13.2222 21.7778C13.2222 21.1028 12.675 20.5556 12 20.5556ZM12 20.5556V16.8889M18.0497 18.0495C17.5724 18.5269 17.5724 19.3007 18.0497 19.778C18.527 20.2553 19.3009 20.2553 19.7782 19.778C20.2555 19.3007 20.2555 18.5269 19.7782 18.0495C19.3009 17.5722 18.527 17.5722 18.0497 18.0495ZM18.0497 18.0495L15.3611 15.3611M16.8889 12C16.8889 14.7001 14.7001 16.8889 12 16.8889M16.8889 12C16.8889 9.29994 14.7001 7.11111 12 7.11111M16.8889 12H20.8611M12 16.8889C9.29994 16.8889 7.11111 14.7001 7.11111 12M7.11111 12C7.11111 9.29994 9.29994 7.11111 12 7.11111M5.88889 18.1111L8.54303 15.457M5.88889 5.88889L8.54303 8.54303M18.1111 5.88889L15.457 8.54303M5.95034 4.22197C6.42764 4.69927 6.42764 5.47314 5.95034 5.95045C5.47303 6.42776 4.69916 6.42776 4.22185 5.95045C3.74455 5.47314 3.74455 4.69927 4.22185 4.22197C4.69916 3.74466 5.47303 3.74466 5.95034 4.22197ZM19.7782 5.95034C19.3009 6.42764 18.527 6.42764 18.0497 5.95034C17.5724 5.47303 17.5724 4.69916 18.0497 4.22185C18.527 3.74455 19.3009 3.74455 19.7782 4.22185C20.2555 4.69916 20.2555 5.47303 19.7782 5.95034ZM5.9503 19.7782C5.47299 20.2555 4.69913 20.2555 4.22182 19.7782C3.74451 19.3009 3.74451 18.527 4.22182 18.0497C4.69913 17.5724 5.47299 17.5724 5.9503 18.0497C6.42761 18.527 6.42761 19.3009 5.9503 19.7782ZM21.7778 10.7778C22.4528 10.7778 23 11.325 23 12C23 12.675 22.4528 13.2222 21.7778 13.2222C21.1028 13.2222 20.5556 12.675 20.5556 12C20.5556 11.325 21.1028 10.7778 21.7778 10.7778Z"
				stroke={stroke}
			/>
		</>
	</Svg>
);

export default DAOIcon;
