export const TABLE_HEADERS = [
	{
		label: '',
		accessor: '',
		className: '',
	},
	{
		label: 'Pool',
		accessor: '',
		className: '',
	},
	{
		label: 'APR',
		accessor: '',
		className: '',
	},
	{
		label: 'TVL',
		accessor: '',
		className: '',
	},
	{
		label: '',
		accessor: '',
		className: '',
	},
];
