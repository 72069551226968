/**
 * SET_STAKING_REQUESTING action types
 */
export const SET_STAKING_REQUESTING_REQUEST = 'SET_STAKING_REQUESTING_REQUEST';

/**
 * SET_STAKING_REQUESTED action types
 */
export const SET_STAKING_REQUESTED_REQUEST = 'SET_STAKING_REQUESTED_REQUEST';

/**
 * SET_STAKING_APPROVING action types
 */
export const SET_STAKING_APPROVING_REQUEST = 'SET_STAKING_APPROVING_REQUEST';

/**
 * SET_STAKING_APPROVED action types
 */
export const SET_STAKING_APPROVED_REQUEST = 'SET_STAKING_APPROVED_REQUEST';

/**
 * SET_STAKING_FULFILLING action types
 */
export const SET_STAKING_FULFILLING_REQUEST = 'SET_STAKING_FULFILLING_REQUEST';

/**
 * SET_STAKING_FULFILLED action types
 */
export const SET_STAKING_FULFILLED_REQUEST = 'SET_STAKING_FULFILLED_REQUEST';
